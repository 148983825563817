<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    BANNER_GET_BANNERS,
    BANNER_INITIAL_GET_BANNERS_STATE
  } from '@/core/store/banner.module';

  export default {
    name: 'BannerList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.banner'),
            route: { name: ROUTE_NAME.BANNER }
          },
          { title: i18nHelper.getMessage('label.bannerList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.BANNER,
        actions: {
          create: ACTION.BANNER_CREATE,
          read: ACTION.BANNER_READ,
          update: ACTION.BANNER_UPDATE,
          delete: ACTION.BANNER_DELETE
        }
      },
      filters: [
        {
          label: 'title',
          key: 'title',
          value: ''
        },
        {
          label: 'hideShow',
          key: 'show',
          value: null,
          options: listService.getHideShowList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'banner',
          state: 'banners',
          action: BANNER_GET_BANNERS,
          initialStateAction: BANNER_INITIAL_GET_BANNERS_STATE,
          title: i18nHelper.getMessage('label.getBanners')
        },
        viewDetails: {
          routeName: ROUTE_NAME.BANNER_DETAILS
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'viewFor',
          name: 'viewBy',
          active: true,
          sortable: true
        },
        {
          key: 'title',
          name: 'title',
          active: true,
          sortable: true
        },
        {
          key: 'seq',
          name: 'sequence',
          active: true,
          sortable: true
        },
        {
          key: 'show',
          name: 'hideShow',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
          config: {
            size: 'lg',
            enumKey: 'enumHideShow',
            statusColor: (value) => {
              let color = {
                0: 'danger',
                1: 'success'
              };

              return color[value];
            }
          }
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true,
          sortField: 'updateAtTimeStamp'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          active: true,
          sortable: true,
          sortField: 'updateAtTimeStamp'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newBanner'),
        routeName: ROUTE_NAME.BANNER_NEW
      }
    })
  };
</script>

<style></style>
